import React from 'react';
import s from './Tab.scss';
import classNames from 'classnames';
import {InfoSectionLayoutId, keyboardEvents} from '../../../../../constants';
import {InfoSectionTitle} from '../../../InfoSectionTitle/InfoSectionTitle';
import {IProvidedTranslationProps, withTranslations} from '../../../../../providers/globalPropsProvider';

export enum DataHook {
  Tab = 'collapse-info-item',
}
export interface ForwardRefProps {
  forwardedRef: React.RefObject<HTMLLIElement>;
}

interface InnerTabProps extends IProvidedTranslationProps {
  index: number;
  title: string;
  isActive: boolean;
  handelToggleActive(index: number): void;
}

export type TabProps = InnerTabProps & ForwardRefProps;

@withTranslations
class Tab extends React.Component<TabProps> {
  public readonly onToggleActive = () => {
    const {handelToggleActive, index} = this.props;
    handelToggleActive(index);
  };

  public readonly onKeypressToggleActive = (e: React.KeyboardEvent<HTMLLIElement>) => {
    const {handelToggleActive, index} = this.props;

    if (e.keyCode === keyboardEvents.ENTER.keyCode) {
      handelToggleActive(index);
    } else if (e.keyCode === keyboardEvents.ARROW_RIGHT.keyCode) {
      e.preventDefault();
      e.stopPropagation();
      handelToggleActive(index + 1);
    } else if (e.keyCode === keyboardEvents.ARROW_LEFT.keyCode) {
      e.preventDefault();
      e.stopPropagation();
      handelToggleActive(index - 1);
    }
  };

  public render() {
    const {title, forwardedRef, isActive, t} = this.props;
    const tabClass = classNames(s.tab);

    return (
      <li
        aria-current={isActive}
        tabIndex={isActive ? 0 : -1}
        className={tabClass}
        onKeyPress={this.onKeypressToggleActive}
        onKeyDownCapture={this.onKeypressToggleActive}
        onClick={this.onToggleActive}
        data-hook={DataHook.Tab}
        ref={forwardedRef}
        role="tab"
        title={t('SR_USE_ARROWS')}>
        <InfoSectionTitle title={title} theme={InfoSectionLayoutId.Tabs} />
      </li>
    );
  }
}

function withRef(Component) {
  class CompWithRef extends React.Component<TabProps> {
    public render() {
      const {forwardedRef, ...rest} = this.props;
      return <Component forwardedRef={forwardedRef} {...rest} />;
    }
  }

  return React.forwardRef((props: InnerTabProps, ref: React.RefObject<HTMLLIElement>) => {
    return <CompWithRef {...props} forwardedRef={ref} />;
  });
}

export const TabWithRef = withRef(Tab);
