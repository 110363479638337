import * as _ from 'lodash';
import {ProvidedGlobalProps} from '../providers/globalPropsProvider';
import {DimensionsConfigUnit} from '../types/app-types';

export function last<T>(array: T[]): T {
  return array[array.length - 1];
}

export function first<T>(array: T[]): T {
  return array[0];
}

export function convertCssValueToConfig(cssValue: string): DimensionsConfigUnit {
  if (/^(\d+px)$/.test(cssValue)) {
    const n = /\d+/.exec(cssValue)[0];
    return {num: parseInt(n, 10), unit: 'px'};
  } else if (/^(\d+\.\d+px)$/.test(cssValue)) {
    const n = /\d+\.\d+/.exec(cssValue)[0];
    return {num: parseFloat(n), unit: 'px'};
  }
  return null;
}

export function convertConfigToNumber(dimensions: DimensionsConfigUnit, base: number): number {
  const {unit, num} = dimensions;
  if (unit === 'px') {
    return num;
  }
  if (unit === '%' && _.isNumber(num)) {
    return base * (num / 100);
  }
  return 0;
}

export function all(...promises: Promise<any>[]): Promise<any[]> {
  return Promise.all(promises);
}

export function isSantaFullWidthMode(props: Partial<ProvidedGlobalProps>) {
  const {
    globals: {
      dimensions: {width},
    },
  } = props;
  return String(width) === '';
}

export function emptyArrayOf(n: number, initialValue?: any) {
  return Array(n).fill(initialValue);
}

export function stripHtmlTags(input: string) {
  return input.replace(/<(?:.|\n)*?>/gm, '');
}

export function capitalizeFirstLetters(string: string = '') {
  return string
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLocaleLowerCase())
    .join(' ');
}
