import React from 'react';
import {withStyles} from '@wix/native-components-infra';
import {withGlobalPropsProvider} from '../providers/globalPropsProvider';
import {getRuntimeStyleParams} from './styleParamsService';
import {withDefaultStyleParams} from '@wix/wixstores-client-common-components/dist/src/outOfIframes/defaultStyleParams/withDefaultStyleParams';
import {FormFactor, InfoSectionLayoutId, LayoutId, GalleryNavigationType} from '../constants';
import {withPropsDeserializer} from '@wix/native-components-infra/dist/es/src/HOC/propsDeserializer/propsDeserializer';

const withStrictMode = (Component: any): React.FunctionComponent | any => {
  return props => (
    <React.StrictMode>
      <Component {...props} />
    </React.StrictMode>
  );
};

export const bootstrapApp = (Component: React.ComponentType, {cssPath}: {cssPath?: string[]} = {}) => {
  let wrappedComponent;
  const fontStyleParam = false;

  wrappedComponent = withDefaultStyleParams(
    withStyles(withStrictMode(withGlobalPropsProvider(Component)), cssPath ? {cssPath} : {}),
    ({style: {styleParams}, formFactor}) => {
      const mobileOverrides = {
        numbers: {
          productPage_infoSectionTypeId: InfoSectionLayoutId.Collapse,
          productPage_infoSectionColumnNumber: 3,
          productPage_descriptionVisibleLineNumbers: 0,
          productPage_galleryImageRatio: -1,
          productPage_layoutId: LayoutId.Mobile,
        },
        fonts: {
          productPage_galleryNavigationType: {value: GalleryNavigationType.DOTS, fontStyleParam},
        },
      };
      return {
        defaults: getRuntimeStyleParams(styleParams, {
          formFactor,
        }),
        ...(formFactor === FormFactor.Mobile ? {overrides: mobileOverrides} : {}),
      };
    }
  );

  wrappedComponent = withPropsDeserializer(wrappedComponent);
  return wrappedComponent;
};
