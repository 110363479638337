import * as React from 'react';
import {ImageMode} from '../../../../constants';
import classNames from 'classnames';
import s from './ProductImage.scss';
import {IImageDimension, IMedia} from '../../../../types/productDef';
import {getMediaUrl} from '../../../../commons/mediaService';
import {ProvidedGlobalProps, withGlobalProps} from '../../../../providers/globalPropsProvider';

export interface ProductImageItemProps extends ProvidedGlobalProps {
  mediaItem: IMedia;
  imageMode: ImageMode;
  withMagicZoom: boolean;
  productName: string;
  dimensions: IImageDimension;
  mountedToDOM: boolean;
  isSelected: boolean;
  imageLoaded(): any;
}

export interface ProductImageItemState {
  imageLoaded: boolean;
}
export enum DataHook {
  ProductImageItem = 'product-image-item',
  Image = 'product-image',
}

@withGlobalProps
export class ProductImage extends React.Component<ProductImageItemProps, ProductImageItemState> {
  public state = {imageLoaded: false};
  private readonly getMainImageUrl = (targetDimensions: {width: number; height: number}, isSSR = false): string => {
    const {imageMode, mediaItem} = this.props;
    return getMediaUrl(
      mediaItem,
      targetDimensions || mediaItem,
      {
        isSSR,
        imageMode,
      },
      this.props.globals.experiments.isProductPageUseAutoUpscale
    );
  };

  private readonly onImageLoad = () => {
    const {mountedToDOM, imageLoaded} = this.props;
    if (mountedToDOM) {
      imageLoaded();
      this.setState({imageLoaded: true});
    }
  };

  private readonly getImageContainerStyle = () => {
    const {imageLoaded} = this.state;
    return imageLoaded
      ? {}
      : {
          backgroundImage: `url(${this.getMainImageUrl(null, true)})`,
        };
  };

  private readonly getImage = () => {
    const {imageMode, withMagicZoom, mountedToDOM, productName, mediaItem, dimensions, isSelected} = this.props;
    const src = this.getMainImageUrl(dimensions);
    const imageClass = classNames([
      s.media,
      {
        [s.selected]: isSelected,
        [s.imageBeforeMounted]: !mountedToDOM,
        [s.mediaWithZoom]: withMagicZoom,
        [s.imageModeFit]: imageMode === ImageMode.FIT,
        [s.imageModeCrop]: imageMode === ImageMode.CROP,
      },
    ]);

    return (
      <img
        src={src}
        alt={mediaItem.altText || productName}
        data-hook={DataHook.Image}
        className={imageClass}
        onLoad={this.onImageLoad}
      />
    );
  };

  public render() {
    const {imageMode, mountedToDOM} = this.props;
    const imageContainerClass = classNames([
      s.imageContainer,
      {
        [s.imageContainerModeFit]: imageMode === ImageMode.FIT,
        [s.imageContainerModeCrop]: imageMode === ImageMode.CROP,
      },
    ]);
    return (
      <div data-hook={DataHook.ProductImageItem} style={this.getImageContainerStyle()} className={imageContainerClass}>
        {mountedToDOM && this.getImage()}
      </div>
    );
  }
}
