import React from 'react';
import cx from 'classnames';
import {LayoutContext} from '../ProductPageApp/ProductPageApp';
import s from './ProductName.scss';

export interface ProductNameProps {
  name: string;
}

export class ProductName extends React.Component<ProductNameProps> {
  public render() {
    return (
      <LayoutContext.Consumer>
        {layoutName => (
          <h2 data-hook="product-title" className={cx(s.productName, s[`productName__${layoutName}`])}>
            {this.props.name}
          </h2>
        )}
      </LayoutContext.Consumer>
    );
  }
}
