import React from 'react';
import {
  withGlobalProps,
  ProvidedGlobalProps,
  withTranslations,
  IProvidedTranslationProps,
} from '../../providers/globalPropsProvider';
import s from './ProductSku.scss';
import {IOptionSelectionVariant} from '../../types/productDef';

export type ProductSkuProps = ProvidedGlobalProps & IProvidedTranslationProps;

@withGlobalProps
@withTranslations
export class ProductSku extends React.Component<ProductSkuProps> {
  public render() {
    const {t} = this.props;
    const {product} = this.props.globals;
    const sku = (this.props.globals.selectedVariant || ({} as IOptionSelectionVariant)).sku || product.sku;
    const text = sku ? `${t('SKU_LABEL')} ${sku}` : '';

    return (
      <div className={s.sku} data-hook="sku">
        {text}
      </div>
    );
  }
}
