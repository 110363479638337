import React from 'react';
import _ from 'lodash';
import style from './MainMedia.scss';
import classNames from 'classnames';
import SlickSlider, {Settings as SlickSettings} from 'react-slick';
import {ImageMode} from '../../../constants';
import {IMedia, IProduct} from '../../../types/productDef';
import {adjustImageDimensionToContainer, isVideo, getMediaUrl} from '../../../commons/mediaService';
import {dynamicLoadResource, getMagicZoomNodeId} from './MainMediaUtils';
import {ProductGalleryContext} from '../context';
import {ProductThumbnail} from '@wix/wixstores-client-common-components/dist/src/ProductThumbnail';
import {withGlobalProps, ProvidedGlobalProps} from '../../../providers/globalPropsProvider';
import {convertConfigToNumber, isSantaFullWidthMode} from '../../../commons/utils';
import {ProductImage} from './ProductImage/ProductImage';
import {ZoomBox} from './ZoomBox/ZoomBox';
import {ProductEmptyImage} from './ProductEmptyImage/ProductEmptyImage';
import {ProductVideoItem} from './ProductVideoItem/ProductVideoItem';
import {DimensionsConfig} from '../../../types/app-types';

export interface MainMediaProps extends ProvidedGlobalProps {
  imageMode: ImageMode;
  product: IProduct;
  swipeToScroll: boolean;
  layoutDimensions: DimensionsConfig;
  allowMagicZoom: boolean;
  withDynamicHeight: boolean;
}

export interface MainMediaState {
  width: number;
  height: number;
  magicZoom: any;
  mountedDOM: boolean;
  numOfLoadedImages: number;
  magicZoomNeedToBeUpdated: boolean;
}

export enum DataHook {
  productPageMediaVideo = 'product-page-media-video',
  mainMediaImageWrapper = 'main-media-image-wrapper',
  mediaItemLink = 'magic-zoom-link',
  productPageMissingMedia = 'product-page-missing-media',
  productPageMediaSlider = 'product-page-media-slider',
  mainMedia = 'main-media',
  dynamicHeightBackgroundImage = 'dynamic-height-background-image',
  oldImageItem = 'old-image-item',
}

@withGlobalProps
export class MainMedia extends React.Component<MainMediaProps, MainMediaState> {
  public state = {
    width: 0,
    height: 0,
    magicZoom: null,
    mountedDOM: false,
    numOfLoadedImages: 0,
    magicZoomNeedToBeUpdated: false,
  };
  /* tslint:disable:prefer-readonly */
  private _divRef: HTMLDivElement;

  public componentDidMount() {
    const {clientHeight, clientWidth} = this._divRef;
    /* istanbul ignore else: magicZoomPlus will be tested in puppeteer */
    if (this.shouldShowMagicZoom() && !this.state.magicZoom) {
      dynamicLoadResource({
        type: 'link',
        linkAttr: 'href',
        ext: 'css',
        onload: _.noop,
      });
      dynamicLoadResource({
        type: 'script',
        linkAttr: 'src',
        ext: 'js',
        onload: () => {
          window.$mjs(document).jAddEvent('domready', () => {
            setTimeout(() => {
              window.mzOptions = {
                hint: 'off',
                transitionEffect: true,
                autoStart: false,
                expand: 'off',
                zoomPosition: 'inner',
                smoothing: true,
                rightClick: true,
                upscale: true,
                zoomWidth: `${clientWidth}`,
                zoomHeight: `${clientHeight}`,
              };
              this.setState({magicZoom: window.MagicZoom});
            }, 0);
          });
        },
      });
    }

    this.setState({
      height: clientHeight,
      width: clientWidth,
      mountedDOM: true,
    });
  }

  public componentDidUpdate(prevProps: Readonly<MainMediaProps>, prevState: Readonly<MainMediaState>): void {
    if (prevState.numOfLoadedImages !== this.state.numOfLoadedImages) {
      const {clientHeight, clientWidth} = this._divRef;
      this.setState({height: clientHeight, width: clientWidth, mountedDOM: true});
    }
    if (!_.isEqual(prevProps.product.media, this.props.product.media)) {
      this.setState({magicZoomNeedToBeUpdated: true});
    }
  }

  private shouldShowMagicZoom() {
    return this.props.globals.style.styleParams.booleans.productPage_galleryZoom && this.props.allowMagicZoom;
  }

  private getSlickSettings(): SlickSettings {
    const {swipeToScroll} = this.props;
    return {
      arrows: false,
      dots: false,
      draggable: swipeToScroll,
      fade: !swipeToScroll,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 400,
      accessibility: false,
    };
  }

  private isDynamicHeight() {
    const {withDynamicHeight} = this.props;
    return !this.state.mountedDOM && withDynamicHeight;
  }

  private getContainerDOMDimensions(): {width: number; height: number} {
    const {
      layoutDimensions,
      globals: {
        dimensions: {height, width},
      },
    } = this.props;
    const {widthConf, heightConf} = layoutDimensions;

    if (this.state.mountedDOM) {
      return _.pick(this.state, 'width', 'height');
    }
    if (isSantaFullWidthMode(this.props)) {
      widthConf.num = 980;
      widthConf.unit = 'px';
    }
    return {
      width: widthConf ? convertConfigToNumber(widthConf, width) : 0,
      height: heightConf ? convertConfigToNumber(heightConf, height) : 0,
    };
  }

  /* istanbul ignore next: will be checked by puppeteer */
  private getMainImageUrl(
    media: IMedia,
    targetDimensions: {width: number; height: number},
    imageMode: ImageMode
  ): string {
    const {mountedDOM} = this.state;
    return getMediaUrl(media, targetDimensions || media, {
      isSSR: !mountedDOM,
      imageMode,
    });
  }

  private getZoomMainImageUrl(media: IMedia, container: {width: number; height: number}, imageMode: ImageMode): string {
    const target = adjustImageDimensionToContainer(media, container, imageMode, {upsclae: true});
    const bigger = _.mapValues(target, v => v * 2);
    return getMediaUrl(media, bigger, {
      isSSR: false,
      imageMode,
    });
  }

  private imageLoaded = () => {
    const nextNumOfLoadedImages = this.state.numOfLoadedImages + 1;
    this.setState({numOfLoadedImages: nextNumOfLoadedImages});
  };

  private getImage(media: IMedia, isSelected: boolean) {
    const {
      product: {name},
      imageMode,
    } = this.props;

    const containerDomDimensions = this.getContainerDOMDimensions();
    const mediaDimensions = adjustImageDimensionToContainer(media, containerDomDimensions, imageMode);

    return (
      <ProductImage
        mediaItem={media}
        dimensions={mediaDimensions}
        isSelected={isSelected}
        imageMode={imageMode}
        mountedToDOM={this.state.mountedDOM}
        productName={name}
        withMagicZoom={this.shouldShowMagicZoom()}
        imageLoaded={this.imageLoaded}
      />
    );
  }

  private getVideoItem(
    media: IMedia,
    index: number,
    isSelected: boolean,
    onAddVideo: (player: any, index: number) => any
  ): JSX.Element {
    const video = media.videoFiles[0];
    if (!video) {
      return this.getImageItem(media, index, isSelected);
    }
    const mediaDimensions = adjustImageDimensionToContainer(media, this.getContainerDOMDimensions(), ImageMode.CROP);

    return (
      <div key={`media-${index}`} className={style.mediaVideoContainer}>
        <ProductVideoItem
          mountedToDOM={this.state.mountedDOM}
          imageMode={ImageMode.CROP}
          dimensions={mediaDimensions}
          mediaItem={media}
          index={index}
          onAddVideo={onAddVideo}
          video={video}
        />
      </div>
    );
  }

  private getImageItem(media: IMedia, index: number, isSelected: boolean, {empty} = {empty: false}): JSX.Element {
    const {imageMode} = this.props;
    const containerDomDimensions = this.getContainerDOMDimensions();
    return (
      <div
        data-hook={DataHook.mainMediaImageWrapper}
        className={'main-media-image-wrapper-hook'}
        key={`main-media-image-${index}`}>
        <a
          data-hook={DataHook.mediaItemLink}
          id={getMagicZoomNodeId(index)}
          href={this.getZoomMainImageUrl(media, containerDomDimensions, imageMode)}
          onClick={e => e.preventDefault()}
          className={classNames([style.mediaWrapper, 'media-wrapper-hook', style.defaultWidth])}
          style={{height: containerDomDimensions.height}}>
          {empty ? <ProductEmptyImage /> : this.getImage(media, isSelected)}
        </a>
      </div>
    );
  }

  private getMediaItem(
    mediaItem: IMedia,
    index: number,
    selectedMediaItem: IMedia,
    onVideoRendered: (player: any, index: number) => void
  ): JSX.Element {
    const {mountedDOM} = this.state;
    const renderEmptyImage = index > 0 && !mountedDOM;
    const isSelected = mediaItem.url === selectedMediaItem.url;

    return mountedDOM && isVideo(mediaItem)
      ? this.getVideoItem(mediaItem, index, isSelected, onVideoRendered)
      : this.getImageItem(mediaItem, index, isSelected, {empty: renderEmptyImage});
  }

  private getMediaItems(
    selectedMediaItem: IMedia,
    onVideoRendered: (player: any, index: number) => void
  ): JSX.Element | JSX.Element[] {
    const {
      product: {media},
    } = this.props;
    return _.map(media, (mediaItem, index) => this.getMediaItem(mediaItem, index, selectedMediaItem, onVideoRendered));
  }

  private getNoMediaMode(): JSX.Element {
    const {product} = this.props;
    const containerDomDimensions = this.getContainerDOMDimensions();
    return (
      <div
        data-hook={DataHook.productPageMissingMedia}
        style={{height: containerDomDimensions.height}}
        className={style.defaultWidth}>
        <ProductThumbnail
          className={style.NoMediaThumbnail}
          height={containerDomDimensions.height}
          product={product}
          defaultImageSize={97}
          getResizedImageUrl={null}
        />
      </div>
    );
  }

  private getMediaSlider(): JSX.Element {
    const {product, imageMode} = this.props;
    const shouldShowZoom = this.shouldShowMagicZoom();
    const slickSettings = this.getSlickSettings();
    return (
      <ProductGalleryContext.Consumer>
        {({initProductGalleryContext, selectedIndex, registerVideoPlayer, changeSelected}) => {
          const selectedMediaItem = product.media[selectedIndex];
          return (
            <>
              <SlickSlider
                key={`slick-slider-with-height-${this.state.height}`}
                data-hook={DataHook.productPageMediaSlider}
                className={classNames([
                  style.slickSliderHook,
                  'main-media-slick-hook',
                  {['force-full-height']: this.isDynamicHeight()},
                ])}
                ref={slider => initProductGalleryContext(slider)}
                afterChange={changeSelected}
                {...slickSettings}>
                {this.getMediaItems(selectedMediaItem, registerVideoPlayer)}
              </SlickSlider>
              {shouldShowZoom ? (
                <ZoomBox
                  mountedToDOM={this.state.mountedDOM}
                  src={this.getMainImageUrl(selectedMediaItem, null, imageMode)}
                  magicZoom={this.state.magicZoom}
                  selectedIndex={selectedIndex}
                  size={35}
                  shouldBeUpdated={this.state.magicZoomNeedToBeUpdated}
                />
              ) : null}
            </>
          );
        }}
      </ProductGalleryContext.Consumer>
    );
  }

  public render() {
    const {
      product: {media},
    } = this.props;
    return (
      <div data-hook={DataHook.mainMedia} className={style.mainMedia} ref={r => (this._divRef = r)}>
        {_.isEmpty(media) ? this.getNoMediaMode() : this.getMediaSlider()}
      </div>
    );
  }
}
