import * as React from 'react';
import {keyboardEvents} from '../../../../constants';
import {DotWithRef} from './Dot/Dot';
import {ThumbnailWithRef} from './Thumbnail/Thumbnail';
import {IMedia} from '../../../../types/productDef';

interface ForwardRefProps {
  forwardedRef: React.RefObject<HTMLButtonElement>;
}

export interface InnerMediaNavigationItemProps {
  index: number;
  handleClick(index: number);
  withDots: boolean;
  isSelected: boolean;
  isVertical: boolean;
  imgUrl: string;
  mediaItem: IMedia;
}

export type MediaNavigationItemProps = InnerMediaNavigationItemProps & ForwardRefProps;

export class MediaNavigationItem extends React.Component<MediaNavigationItemProps> {
  constructor(props: MediaNavigationItemProps) {
    super(props);
  }

  public readonly onClick = () => {
    const {index, handleClick} = this.props;
    handleClick(index);
  };

  public readonly onKeypress = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    const {handleClick, index} = this.props;

    if (e.keyCode === keyboardEvents.ENTER.keyCode) {
      handleClick(index);
    } else if (e.keyCode === keyboardEvents.ARROW_RIGHT.keyCode || e.keyCode === keyboardEvents.ARROW_UP.keyCode) {
      e.preventDefault();
      e.stopPropagation();
      handleClick(index + 1);
    } else if (e.keyCode === keyboardEvents.ARROW_LEFT.keyCode || e.keyCode === keyboardEvents.ARROW_DOWN.keyCode) {
      e.preventDefault();
      e.stopPropagation();
      handleClick(index - 1);
    }
  };

  public render() {
    const {forwardedRef, isSelected, index, isVertical, imgUrl, mediaItem} = this.props;

    return this.props.withDots ? (
      <DotWithRef
        isSelected={isSelected}
        ref={forwardedRef}
        index={index}
        handleClick={this.onClick}
        handleKeypress={this.onKeypress}
      />
    ) : (
      <ThumbnailWithRef
        handleClick={this.onClick}
        handleKeypress={this.onKeypress}
        index={index}
        ref={forwardedRef}
        isSelected={isSelected}
        isVertical={isVertical}
        mediaItem={mediaItem}
        imgUrl={imgUrl}
      />
    );
  }
}

function withRef(Component) {
  class CompWithRef extends React.Component<MediaNavigationItemProps> {
    public render() {
      const {forwardedRef, ...rest} = this.props;
      return <Component forwardedRef={forwardedRef} {...rest} />;
    }
  }

  return React.forwardRef((props: InnerMediaNavigationItemProps, ref: React.RefObject<HTMLButtonElement>) => {
    return <CompWithRef {...props} forwardedRef={ref} />;
  });
}

export const MediaNavigationItemWithRef = withRef(MediaNavigationItem);
