import {IMedia} from './types/productDef';
import {MediaType} from '@wix/wixstores-client-core/dist/src/types';
import {APP_DEFINITION_ID} from '@wix/wixstores-client-core/dist/src/constants';

export const translationPath = (baseUrl, locale) => `${baseUrl}assets/locale/productPage/productPage_${locale}.json`;
export const widgetId = '13a94f09-2766-3c40-4a32-8edb5acdd8bc';
export const PRODUCT_PAGE_APP_NAME = 'wixstores-product-page';

export const defaultFontSize = 16;

export const enum ArrowsDir {
  LEFT = 'left',
  RIGHT = 'right',
  UP = 'up',
  DOWN = 'down',
}

export enum TabsDirection {
  start = 'left',
  end = 'right',
}

export const enum GalleryNavigationType {
  THUMBNAILS = 'thumbnails',
  DOTS = 'dots',
}

export const enum GalleryNavigationLocation {
  INSIDE = 'inside',
  OUTSIDE = 'outside',
}

export const enum GalleryNavigationPosition {
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export const enum ErrorTooltipPlacement {
  Top = 'top',
  Left = 'left',
  Bottom = 'bottom',
  Right = 'right',
}

export const enum ImageMode {
  CROP = 1,
  FIT = 2,
}

export const enum LayoutId {
  Classic = 1,
  QuickView = 2,
  Mobile = 3,
  Sleek = 4,
  Stunning = 5,
  Spotlight = 6,
  Simple = 7,
}

export const enum Layout {
  Classic = 'classic',
  QuickView = 'quickView',
  Mobile = 'mobile',
  Sleek = 'sleek',
  Stunning = 'stunning',
  Spotlight = 'spotlight',
  Simple = 'simple',
}

export enum InfoSectionLayoutId {
  Collapse = 1,
  Stacked = 3,
  Tabs = 2,
  Columns = 4,
}

export const enum MediaFrameMediaType {
  SECURE_PICTURE = 'secure_picture',
  SECURE_VIDEO = 'secure_video',
  SECURE_DOCUMENT = 'secure_document',
  SECURE_MUSIC = 'secure_music',
  SECURE_ARCHIVE = 'secure_archive',
}

export const enum ProductType {
  DIGITAL = 'digital',
  PHYSICAL = 'physical',
}

export const LayoutMap = {
  [LayoutId.Classic]: Layout.Classic,
  [LayoutId.QuickView]: Layout.QuickView,
  [LayoutId.Mobile]: Layout.Mobile,
  [LayoutId.Sleek]: Layout.Sleek,
  [LayoutId.Stunning]: Layout.Stunning,
  [LayoutId.Spotlight]: Layout.Spotlight,
  [LayoutId.Simple]: Layout.Simple,
};

export interface IconSVGComponent {
  fillColor?: 'string';
}

export const enum FormFactor {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
  Tablet = 'Tablet',
  //remove
  Smartphone = 'Smartphone',
}

export const enum Alignment {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export const enum ProductInventoryStatus {
  OUT_OF_STOCK = 'out_of_stock',
  IN_STOCK = 'in_stock',
}

export const enum ProductInventoryTrackingMethod {
  STATUS = 'status',
  QUANTITY = 'quantity',
}

export const enum ProductOptionType {
  COLOR = 'COLOR',
  DROP_DOWN = 'DROP_DOWN',
}

export interface IKeyboardEvent {
  keyCode: number;
  charCode: number;
}

export const keyboardEvents: {[key: string]: IKeyboardEvent} = {
  ENTER: {keyCode: 13, charCode: 13},
  SPACEBAR: {keyCode: 32, charCode: 13},
  ARROW_UP: {keyCode: 38, charCode: 38},
  ARROW_DOWN: {keyCode: 40, charCode: 40},
  ARROW_RIGHT: {keyCode: 39, charCode: 39},
  ARROW_LEFT: {keyCode: 37, charCode: 37},
};

export const enum SocialVendor {
  Facebook = 'facebook',
  Google = 'google',
  Twitter = 'twitter',
  Pinterest = 'pinterest',
  Fancy = 'fancy',
}

export const ImageRatioList = [
  {
    sdkId: 0,
    title: '3:2',
    ratio: {width: 3, height: 2},
  },
  {
    sdkId: 1,
    title: '4:3',
    ratio: {width: 4, height: 3},
  },
  {
    sdkId: 2,
    title: '1:1',
    ratio: {width: 1, height: 1},
  },
  {
    sdkId: 3,
    title: '3:4',
    ratio: {width: 3, height: 4},
  },
  {
    sdkId: 4,
    title: '2:3',
    ratio: {width: 2, height: 3},
  },
];

export interface IconSVGComponent {
  fillColor?: 'string';
}

export const defaultProductMediaImage: IMedia = {
  url: 'eaa00d_b02f06cef98b454c8b56a1dcebdacee4.jpg',
  mediaType: MediaType.PHOTO,
  title: '',
  index: 1,
  width: 800,
  height: 800,
};

export const enum UserInputType {
  Selection = 'selection',
  Text = 'text',
  Quantity = 'quantity',
}

export const defaultQuantity = 1;

export const enum productPageTrackEvent {
  AddToCart = 'AddToCart',
  ViewContent = 'ViewContent',
}

export const trackEventMetaData = Object.freeze({
  appDefId: APP_DEFINITION_ID,
  category: 'All Products',
  origin: 'Stores',
});

export const enum graphqlOperation {
  GetDefaultProduct = 'getDefaultProduct',
  GetProductBySlug = 'getProductBySlug',
  GetNextPrevProducts = 'getNextPrevProducts',
}

export const GRAPHQL_SOURCE = 'WixStoresWebClient';

export const MULTILINGUAL_TO_TRANSLATIONS_MAP = {ADD_TO_CART_BUTTON: 'PRODUCT_PAGE_BUTTON_TEXT'};

export const enum MarginBottom {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}
